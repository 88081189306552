//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Search from '@/components/Search'
export default {
    name: 'Product',
    components: {Search},
    data() {
        return {
			uniacid:0,
			webset:[],
            productInfo: null,
            goodsId: '',
            detail: '',
            goods: '',
            number: 1,
            goods_sku_key: '',
        }
    },
    created() {
        this.goodsId = this.$route.query.id
		var ss = window.localStorage.getItem('webinfo');
		var ssarray = JSON.parse(ss)
		 
		this.webset =  ssarray
		this.uniacid = ssarray['ucid']
		
        this.getGoodsDetail()
    },
    methods: {
        //下单
        orderConfirm() {
            this.onConfirmClick('buy')
        },

        async onConfirmClick(code) {
            const res = await this.$http.post('/cart/add', {
                user_id: window.localStorage.getItem('user_id'),
                goods_id: this.goodsId,
                goods_sku_key: this.productInfo.sku.list[0].goods_sku_key,
                goods_number: this.number,
                promotion_code:  this.productInfo.basic.field.promotion_code,

                is_checked: 1,
                is_direct: code === 'buy' ? 1 : 0
            })
            // 添加 res
            if (res.code !== 0) {
                 return this.$message.error(res.message);
            }else{
                // this.$router.push({name: 'Cart'})
            }
            if (code === 'buy') {
                this.$router.push({name: 'orderConfirm', params:{cart_id: res.data.cart_id}})
            }
        },

        //修改數量
        changeNumber(type) {
            if(type == 'add'){
                this.number += 1
            }else{
                this.number>1 && (this.number -= 1)
            }
        },
        // 获取商品信息
        async getGoodsDetail() {
            if (!this.goodsId) return
            const res = await this.$http.get('/goods/detail', {
                goods_id: this.goodsId
            })
            let data = res.data
            data.basic.field.detail_mobile = JSON.parse(data.basic.field.detail_mobile)
            this.detail=  data.basic.field.detail        
            this.goods = data.basic.field
            this.response = data
            this.productInfo = res.data
        },

        //添加到購物車
        async addCart(code) {
            const res = await this.$http.post('/cart/add', {
                user_id: window.localStorage.getItem('user_id'),
                goods_id: this.goodsId,
                goods_sku_key: this.productInfo.sku.list[0].goods_sku_key,
                goods_number: this.number,
                promotion_code:  this.productInfo.promotion_code,
                is_checked: 1,
                is_direct: code === 'buy' ? 1 : 0
            })
            console.log(res)
            if (res.code != 0) {
                if (res.data.code === 401) {
                    window.location.href='/#/login'
                    return false
                }
                return this.$message.error(res.message);
            }else{

                this.$message({message: '添加成功', type: 'success'})
            }
        }
    }
}
